import {NewsModelApiCustomView, NewsModelView} from "./models/NewsModelView";
import {mapper} from "../../utils/mapper/mapper";
import {
    NewsModelApiCustomData,
    NewsModelData, NewsThemeModelData,
    newsValidationApiCustomSchema,
    newsValidationSchema
} from "./models/NewsModelData";
import {OnSearchFormData} from "../page/page-model/news-page/NewsPage";
import {useApiQuery} from "../../data-access/data-caching";
import {GetPagesData, GetPagesVariables} from "../../data-access/api-call/wagtail-rest-page-api/page";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ApiCallContext from "../../data-access/api-call/ApiCallContext";
import {ApiImageData} from "../../data-access/api-call/wagtail-rest-page-api/image/models/ImageModel";
import {convertApiImageDataToImageAttributs} from "../../data-access/api-call/wagtail-rest-page-api/utils";
import {
    GetThemeItemData,
} from "../../data-access/api-call/wagtail-rest-snippet-api/theme/models/ThemeModel";
import {useTranslationService} from "../../translation/TranslationProvider";

export const NEWS_PAGE_WAGTAIL_MODEL_NAME = "news.NewsPage"
export const NEWS_PAGE_QUERY_NAME = "get_news_page"
export const NEWS_PAGE_MODEL_NAME = "News Page"

export const convertThemesForNewsModelDataToThemesForNewsModelView = (data: Array<GetThemeItemData>, language?: string) => {
    return data.filter((theme: GetThemeItemData) => {
        return theme?.locale === language
    }).sort((a, b) => a?.order !== undefined && b?.order !== undefined && a?.order > b?.order ? 1 : -1).map((theme: GetThemeItemData) => ({id: theme.id, name: theme.name}))
}

/**
 * Description - Method to convert news model data to news model view
 * @param data
 */
export const convertNewsModelDataToNewsModelView = (data: NewsModelData): NewsModelView => {
    return mapper<NewsModelView>(data, {
        "title":"title",
        "abstract": "abstract",
        "published_date":"published_date",
        "themes": {
            key: "news_page_theme",
            optional: true,
            serializer: (themes) => themes.map(({theme}: {theme: GetThemeItemData}) => ({id: theme.id, name: theme.name}))
        },
        "tag": {
            key: "tags",
            optional: true
        },
        "url": {
            key: "source_url",
            optional: true,
        },
        "detail_page_id": {
            key: "source_url_internal",
            optional: true,
            serializer: (value: {id: number}) => value.id.toString()
        },
        "image": {
            key: "image",
            optional: true,
            serializer: (value: ApiImageData) => convertApiImageDataToImageAttributs(value)
        }
    })
}

export const convertNewsModelDataToNewsModelViewForCustomNewsApiForPreview = (data: NewsModelData, language?: string): NewsModelApiCustomView => {
    // @ts-ignore
    return mapper<NewsModelApiCustomView>(data, {
        "title":"title",
        "abstract": "abstract",
        "published_date":"published_date",
        "themes": {
            key: "news_page_theme",
            optional: true,
            serializer: (themes: Array<NewsThemeModelData>) => convertThemesForNewsModelDataToThemesForNewsModelView(themes.map(({theme}) => theme), language)
        },
        "tag": {
            key: "tags",
            optional: true
        },
        "url": {
            key: "source_url",
            optional: true,
            serializer: (value: string | null) => value ? value : "225"
        },
        "detail_page_id": {
            key: "source_url_internal",
            optional: true,
            serializer: (value: number) => value.toString()
        },
        "image": {
            key: "image",
            optional: true,
            serializer: (value: ApiImageData) => value.id
        }
    })
}

export const convertNewsModelDataToNewsModelViewForCustomNewsApi = (data: NewsModelData, language?: string): NewsModelApiCustomView => {
    // @ts-ignore
    return mapper<NewsModelApiCustomView>(data, {
        "title":"title",
        "abstract": "abstract",
        "published_date":"published_date",
        "themes": {
            key: "news_page_theme",
            optional: true,
            serializer: (themes: Array<NewsThemeModelData>) => convertThemesForNewsModelDataToThemesForNewsModelView(themes.map(({theme}) => theme), language)
        },
        "tag": {
            key: "tags",
            optional: true
        },
        "url": {
            key: "source_url",
            optional: true,
        },
        "detail_page_id": {
            key: "source_url_internal",
            optional: true,
            serializer: (value: number) => value.toString()
        },
        "image": {
            key: "image",
            optional: true,
            serializer: (value: ApiImageData) => convertApiImageDataToImageAttributs(value)
        }
    })
}

/**
 * Description - Method to convert news model data to news model view for custom api
 * @param data
 * @param language
 */
export const convertNewsModelApiCustomDataToNewsModelViewForCustomNewsApi = (data: NewsModelApiCustomData, language?: string): NewsModelApiCustomView => {
    return mapper<NewsModelApiCustomView>(data, {
        "title":"title",
        "abstract": "abstract",
        "published_date":"published_date",
        "themes": {
            key: "themes",
            optional: true,
            serializer: (themes: Array<GetThemeItemData>) => convertThemesForNewsModelDataToThemesForNewsModelView(themes, language)
        },
        "tag": {
            key: "tags",
            optional: true
        },
        "url": {
            key: "source_url",
            optional: true,
        },
        "detail_page_id": {
            key: "source_url_internal",
            optional: true,
            serializer: (value: number) => value.toString()
        },
        "image": {
            key: "image",
            optional: true,
            serializer: (value: number) => value.toString()
        }
    })
}

export interface UsePaginationVariable {
    total?: number
}

export interface PaginationService {
    current: number,
    pageSize: number
    onChangeCurrent: (current: number | ((current: number | undefined) => number)) => void
    onChangePageSize: (pageSize: number) => void
    onResetCurrent: () => void
    setCount?: (count: number) => void,
    count?: number
    total?: number,
    setTotal?: (total:number) => void
    onNext: () => void
}
export interface Pagination {
    current?: number;
    pageSize?: number;
}

export const DEFAULT_TOTAL: number = 10;

export interface IdElement {
    id: string | number;
}

/**
 * Description - Service to manage paginationService
 * @param variables
 */
export function usePagination(variables: UsePaginationVariable): PaginationService {
    const [pagination, setPagination] = useState<Pagination | undefined>({
        current: 0,
        pageSize: variables.total ? variables.total : DEFAULT_TOTAL
    })
    const [total, setTotal] = useState<number>(0)

    const onChangeCurrent = useCallback((pageActive: number | ((current: number | undefined) => number)) => {
        if(typeof pageActive === "number" ){
            setPagination(currentPagination => ({...currentPagination, current: pageActive}))
        } else {
            setPagination(currentPagination => ({...currentPagination, current: pageActive(currentPagination?.current)}))
        }

    }, [])

    const onChangePageSize = useCallback((pageSize: number) => {
        setPagination(currentPagination => ({...currentPagination, pageSize}))
    }, [])

    const onResetCurrent = useCallback(() => {
        setPagination(currentPagination => ({...currentPagination, current: 0}))
    }, [])

    const count = useMemo(() => {
        if (total && pagination?.pageSize) {
            return Math.ceil(total / pagination.pageSize)
        }
        return 0
    }, [total, pagination])


    const onNext = useCallback(() => {
        onChangeCurrent((current) => {
            if(current !== undefined && pagination?.pageSize !== undefined){
                return current + pagination.pageSize
            }
            return 0
        })
    }, [onChangeCurrent, pagination?.pageSize])

    return {
        current: pagination?.current !== undefined ? pagination.current : 0,
        pageSize: pagination?.pageSize !== undefined ? pagination.pageSize : 0,
        onResetCurrent,
        onChangeCurrent,
        onChangePageSize,
        total,
        setTotal,
        count,
        onNext
    }
}

export interface OnSearchVariables extends  GetPagesVariables {
    theme?: string;
    tags?: string;
}

const defaultVariables: GetPagesVariables = {
    order: "-published_date",
    type: NEWS_PAGE_WAGTAIL_MODEL_NAME,
    fields:"*",
}

export const SEARCH_NEWS_PAGINATION_PAGE_SIZE = 20

/**
 * Description - Service to fetch news by text search (title), tag, and theme from wagtail rest api
 * @param searchFormData
 */
export const useSearchNews = (searchFormData?: OnSearchFormData) => {
    const {getNews} = useContext(ApiCallContext)
    const {language} = useTranslationService()
    const pagination = usePagination({total: SEARCH_NEWS_PAGINATION_PAGE_SIZE})

    const [item, setItem] = useState<Array<NewsModelApiCustomView>>([])

    const [variables, setVariables] = useState<(GetPagesVariables)>({...defaultVariables, offset: pagination.current, limit: pagination.pageSize})

    const [notMoreData, setNotMoreData] = useState<boolean>(false)
    useEffect(() => {
        setItem([])
        setVariables((current) => {
            pagination.onChangeCurrent(0)
            return {...current, offset: 0}
        })
        // eslint-disable-next-line
    }, [language, pagination.onChangeCurrent])

    useEffect(() => {
        setVariables((current) => {
            // optimized to don't update of pagination value is the same of variables pagination
            if(current.offset === pagination.current && current.limit === pagination.pageSize){
                return current
            }
            return {...current, offset: pagination.current, limit: pagination.pageSize}
        })
        // eslint-disable-next-line
    }, [pagination.current, pagination.pageSize])

    useEffect(() => {
        setVariables((current) => {
            const result: OnSearchVariables = {};
            if(!searchFormData){
                return current
            }
            window.scrollTo({ top: 0});
            setItem([])
            setNotMoreData(false)
            pagination.onChangeCurrent(0)

            const {tag, titleQuery, theme} = searchFormData

            if(tag && tag !== ""){
                result.tags = tag
            }
            if(titleQuery !== ""){
                result.search = titleQuery
                result.search_operator="and"
            }
            if(theme !== "" && theme !== -1){
                result.theme = theme.toString()
            }
            result.offset = 0
            return {
                ...defaultVariables,
                ...result,
                limit: current.limit,
            }
        })
        // eslint-disable-next-line
    },[searchFormData, pagination.onChangeCurrent])

    const {data, isLoading, error} = useApiQuery<GetPagesData<NewsModelApiCustomData>>(NEWS_PAGE_QUERY_NAME, getNews, {
        variables,
        schemaValidation: newsValidationApiCustomSchema,
        model: NEWS_PAGE_MODEL_NAME,
    })

    useEffect(() => {
        if (data && (!data.items.length || (data.items.length < pagination.pageSize && pagination.current > 1))){
            setNotMoreData(true)
        }
        if (data === undefined && error !== undefined){
            setNotMoreData(true)
        }
        // eslint-disable-next-line
    }, [data, pagination.current, pagination.pageSize, error])

    const onNext = useCallback(() => {
        if(!notMoreData) {
            setTimeout(() => {
                pagination.onChangeCurrent((currentValue?: number) => {
                    if (currentValue !== undefined){
                        return currentValue + pagination.pageSize
                    } else {
                        return 0
                    }
                })
            }, 1000)
        }
        // eslint-disable-next-line
    }, [pagination.onChangeCurrent, pagination.pageSize, notMoreData])

    useEffect(() => {
        if(data?.items.length){
            setItem(currentItem => {
                // ref is title because doesn't exist in news api custom model view
                const ids = currentItem.map(({title}) => title)
                data.items.forEach(dataItem => {
                    if (!ids.includes(dataItem.title)){
                        currentItem.push(convertNewsModelApiCustomDataToNewsModelViewForCustomNewsApi(dataItem, language))
                    }
                })
                return [...currentItem]

            })
        }
    }, [data, language])
    return {data: item, isLoading, error, onNext}
}

/**
 * Description - Service to fetch 5 last news from wagtail rest api
 */
export const useGetLastNews = () => {
    const {getPages} = useContext(ApiCallContext)
    const {data, isLoading, error} = useApiQuery<GetPagesData<NewsModelData>>(NEWS_PAGE_QUERY_NAME, getPages, {
        variables: {
            limit: 5,
            ...defaultVariables
        },
        schemaValidation: newsValidationSchema,
        model: NEWS_PAGE_MODEL_NAME
    })

    const newsView = useMemo(() => {
        return  data && data.items.length && !isLoading ? data.items.map(newsItem => convertNewsModelDataToNewsModelView(newsItem)) : undefined
    },[data, isLoading])

    return {data: newsView, isLoading, error}
}