import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {
    RegistrationErrorsModelData,
    RegistrationFormData,
    registrationFormSchema
} from "../../../../../registration/models/RegistrationModelData";
import {yupResolver} from "@hookform/resolvers/yup"
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import ThemeMultiSelect from "./ThemeMultiSelect";
import {useTranslation} from "react-i18next";
import {useRegistrationMutation} from "../../../../../registration/RegistrationData";
import FadeTransition from "../../../../../ui/transition/FadeTransition";
import {useTranslationService} from "../../../../../../translation/TranslationProvider";
import CaptchaFormField from "../../../../../captcha/ui/CaptchaFormField";
import {RegistrationFormCriticalError, RegistrationFormSuccessPanel} from "./registration-form-message-panel";
import {useCaptchaValueFormMutation, useThemeValueFormMutation} from "./registration-form-service";
import {usePreview} from "../../../../../../preview/PreviewService";
import RegistrationFormGdpr from "./registration-form-gdpr/RegistrationFormGdpr";
import OverlayToolTip from "../../../../../ui/tooltip/OverlayToolTip";

export const testIds = {
    formContent: "registration-form-content-container",
    formSuccess: "registration-form-success-container",
    formCriticalError: "registration-form-critical-error-container",
    spinnerButtonSubmit: "registration-form-spinner-button-submit"
}

export const labelKeys = {
    firstnamePlaceholder: "page.registration-page.form.placeholder.firstname",
    lastnamePlaceholder: "page.registration-page.form.placeholder.lastname",
    emailPlaceholder: "page.registration-page.form.placeholder.email",
    organizationPlaceholder: "page.registration-page.form.placeholder.organization",
    themePlaceholder: "page.registration-page.form.placeholder.theme",
    buttonSubmit: "page.registration-page.form.submit-button",
    messageSuccess: "page.registration-page.form-success.message",
    buttonNavigationSuccess: "page.registration-page.form-success.navigation-button",
    emailAlreadyExistErrorMessage: "page.registration-page.form.errorMessage.emailAlreadyExist",
    criticalError: "page.registration-page.form.errorMessage.errorCritical",
    confirmGdprSubmitButtonTooltipMessage: "page.registration-page.form.confirm-gdpr-submit-tooltip-message"
}

export const EMAIL_ERROR_ALREADY_EXIST_KEY = "email already existing"

export interface RegistrationFormContentProps {
    onSubmit: (formInputs: RegistrationFormData) => void
    errorsBackend: RegistrationErrorsModelData | null
    isLoading?: boolean
}

export const registrationDefaultValue: RegistrationFormData = {
    first_name: "",
    last_name: "",
    email: "",
    organisation: "",
    themes: [],
    captcha_code: "",
    captcha_hashkey: ""
}

/**
 * Description - UI Component to manage and render registration form content:
 * @param onSubmit
 * @param errorsBackend
 * @param isLoading
 * @constructor
 */
export const RegistrationFormContent: React.FC<RegistrationFormContentProps> = ({onSubmit, errorsBackend, isLoading}) => {

    const {t} = useTranslation()
    const [confirmGdpr, setConfirmGdpr] = useState<boolean>(true)

    const {register, handleSubmit, formState: {errors}, watch, setValue, setError} = useForm<RegistrationFormData>({
        defaultValues: registrationDefaultValue,
        // @ts-ignore
        resolver: yupResolver(registrationFormSchema)
    })

    const handleConfirmGdpr = useCallback((gdprConfirmNewValue: boolean) => {
        setConfirmGdpr(!gdprConfirmNewValue)
    }, [])

    useEffect(() => {
        if(errorsBackend){
            Object.keys(errorsBackend).forEach((errorKey) => {
                // @ts-ignore
                if(errorsBackend[errorKey] && errorsBackend[errorKey].length){
                    // @ts-ignore
                    setError(errorKey, {message: errorsBackend[errorKey][0]})
                }
            })
            if(errorsBackend.email?.length && errorsBackend.email[0] === EMAIL_ERROR_ALREADY_EXIST_KEY){
                setError("email", {message: t(labelKeys.emailAlreadyExistErrorMessage)})
            }
        }
    },[errorsBackend, setError, t])

    const {value: themesValue, handleChange: handleChangeThemes} = useThemeValueFormMutation({setValue, watch})

    const {handleKey: handleCaptchaKey, errorFormValidation: errorCaptchaFormValidation , haveErrorBackend: haveCaptchaErrorBackend} = useCaptchaValueFormMutation({setValue, errors, errorsBackend})

    const handleSubmitValidate = useCallback((formData: RegistrationFormData) => {
        // handleSubmit from form validation inject other value (html value)
        const {first_name, last_name, themes, organisation, email, captcha_code, captcha_hashkey} = formData
        onSubmit({first_name, last_name, themes, organisation, email, captcha_code, captcha_hashkey})
    },[onSubmit])

    const {isPreview} = usePreview()

    const buttonDisable = useMemo(() => {
        return isPreview 
            ? true
            : confirmGdpr 
    }, [isPreview, confirmGdpr])

    return <Container className="registration-form padding-top-1 padding-bottom-1 margin-bottom-2">
        <Form onSubmit={handleSubmit(handleSubmitValidate)}>
            <Row className="justify-content-md-center">
                <Col xs={12} md={6}>
                    <Form.Group className="form-group" controlId="first-name-control">
                        <Form.Label>{t("term.firstname")}</Form.Label><span>{" *"}</span>
                        <Form.Control
                            isInvalid={errors.first_name !== undefined}
                            type="text"
                            placeholder={t(labelKeys.firstnamePlaceholder)}
                            {...register("first_name")}
                        />
                        {errors.first_name !== undefined && <Form.Text
                          className="text-danger"
                        >
                            {errors.first_name.message}
                        </Form.Text>}
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group className="form-group" controlId="last-name-control">
                        <Form.Label>{t("term.lastname")}</Form.Label><span>{" *"}</span>
                        <Form.Control
                            isInvalid={errors.last_name !== undefined}
                            type="text"
                            placeholder={t(labelKeys.lastnamePlaceholder)}
                            {...register("last_name")}
                        />
                        {errors.last_name !== undefined && <Form.Text
                          className="text-danger"
                        >
                            {errors.last_name.message}
                        </Form.Text>}
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="form-group" controlId="organisation-control">
                        <Form.Label>{t("term.organization")}</Form.Label><span>{" *"}</span>
                        <Form.Control
                            isInvalid={errors.organisation !== undefined}
                            type="text"
                            placeholder={t(labelKeys.organizationPlaceholder)}
                            {...register("organisation")}
                        />
                        {errors.organisation !== undefined && <Form.Text
                          className="text-danger"
                        >
                            {errors.organisation.message}
                        </Form.Text>}
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="form-group" controlId="email-control">
                        <Form.Label>{t("term.email")}</Form.Label><span>{" *"}</span>
                        <Form.Control
                            isInvalid={errors.email !== undefined}
                            type="email"
                            placeholder={t(labelKeys.emailPlaceholder)}
                            {...register("email")}
                        />
                        {errors.email !== undefined && <Form.Text
                          className="text-danger"
                        >
                            {errors.email.message}
                        </Form.Text>}
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <ThemeMultiSelect errorMessage={errors.themes?.message} onChange={handleChangeThemes} value={themesValue}/>
                </Col>
                <Col xs={12}>
                    <CaptchaFormField setKey={handleCaptchaKey} register={{...register("captcha_code")}} errors={errorCaptchaFormValidation} errorFromBackend={haveCaptchaErrorBackend}/>
                </Col>
                <Col xs={12}>
                    <RegistrationFormGdpr onConfirm={handleConfirmGdpr}/>
                </Col>
                <Col xs={12}>
                    <OverlayToolTip disabled={!confirmGdpr} label={t(labelKeys.confirmGdprSubmitButtonTooltipMessage)}>
                        <Button className={`margin-top-1 ${isLoading ? "button-loading" : ""}`} variant="outline-primary" disabled={buttonDisable} type="submit">
                            {isLoading ? <Spinner data-testid={testIds.spinnerButtonSubmit} size="sm" variant="primary" animation="border"/> : t(labelKeys.buttonSubmit)}
                        </Button>
                    </OverlayToolTip>
                </Col>
            </Row>
        </Form>
    </Container>
}

/**
 * Description - Component to render registration form and render success panel when registration is successfully
 * @constructor
 */
const RegistrationForm: React.FC = () => {

    const registrationMutation = useRegistrationMutation()

    const {mutate, isSuccess, isLoading, error, isCriticalError} = registrationMutation
    const {language} = useTranslationService()

    const ref = useRef(null)

    const onSubmit = useCallback((formInput: RegistrationFormData) => {
        // inject formvalue and user language
        mutate({...formInput, locale: language})
    },[mutate, language])

    const formStep: "in-progress" | "success" | "critical-error" = useMemo(() => {
        if(isCriticalError){
            return "critical-error"
        }
        if(isSuccess){
            return "success"
        }
        return "in-progress"
    },[isCriticalError, isSuccess])

    return <FadeTransition activeKey={formStep} nodeRef={ref}>
        {formStep === "in-progress"
            ? <div data-testid={testIds.formContent}>
                <RegistrationFormContent onSubmit={onSubmit} errorsBackend={error} isLoading={isLoading}/>
                </div>
            : formStep === "success"
                ? <div data-testid={testIds.formSuccess}><RegistrationFormSuccessPanel/></div>
                : <div data-testid={testIds.formCriticalError}><RegistrationFormCriticalError/></div>
        }
    </FadeTransition>
}

export default RegistrationForm