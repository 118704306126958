import * as yup from "yup"
import {requiredMessage} from "../../../models/error/ModelErrorValidation";

export interface RegistrationModelData {
    first_name: string;
    last_name: string;
    email: string;
    organisation?: string;
    themes: Array<number>;
    locale: string;
    captcha_code: string;
    captcha_hashkey: string;
}


export interface RegistrationFormData {
    first_name: string;
    last_name: string;
    email: string;
    organisation?: string;
    themes: Array<number>;
    captcha_code: string;
    captcha_hashkey: string;
}

export type RegistrationModelDataKey = "first_name" |"last_name" | "email" | "organisation" | "themes" | "captcha" | "captcha_code" | "captcha_hashkey"

export type RegistrationErrorsModelData = {[key in RegistrationModelDataKey]?: Array<string> }

export const registrationFormSchema = yup.object({
    first_name: yup.string().required(requiredMessage),
    last_name: yup.string().required(requiredMessage),
    email: yup.string().required(requiredMessage),
    organisation: yup.string().required(requiredMessage),
    themes: yup.array(yup.number()).required(requiredMessage).min(1,requiredMessage),
    captcha_code: yup.string().required(requiredMessage),
    captcha_hashkey: yup.string().required(requiredMessage)
    // user locale value is injected in locale property
})