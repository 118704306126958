import React, {useMemo} from "react";
import {Navbar} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const FEDERAL_WEB_SITE = "https://www.belgium.be"
const FEDERAL_WEB_SITE_LABEL = "www.belgium.be"

/**
 * Description - UI Component to render right side of federal top bar
 * - Link to federal website
 * - Federal logo
 * @constructor
 */
const FedRightSide: React.FC = () => {
    const {t} = useTranslation()

    const linkLabel = useMemo(() => `${t("fedTopBar.linkLabel")} : ${FEDERAL_WEB_SITE_LABEL}`, [t])
    return (
            <Navbar.Brand className="justify-content-end FedRightSide">
                <a
                    href={FEDERAL_WEB_SITE}
                >
                    <span className="xs-hidden">{linkLabel}</span>
                    <span className="xs-display">{FEDERAL_WEB_SITE_LABEL}</span>
                </a>
                {" "}
                <img
                    src={process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/federal-logo.png` : "/federal-logo.png"}
                    width={16}
                    height={16}
                    alt="federal-logo"
                />

            </Navbar.Brand>
    )
}

export default FedRightSide